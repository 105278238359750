import {TableSearchState, ITableState} from "./table.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  setAdminOrganizationDashboardStateAction,
  setAdminOrganizationEventsStateAction,
  setAdminPlacementDashboardStateAction,
  setAdminPlacementEventsStateAction,
  setAdminSupervisorDashboardStateAction,
  setAdminSupervisorEventsStateAction,
  setAdminUserDashboardStateAction,
  setAdminUserVolunteerDashboardStateAction,
} from './table.actions';
import { TableData } from '../../../shared/model/tableData.model';
import {ITableOptions} from "../../../shared/model/table-options.model";

const initialTableOptions = <ITableOptions> {
  currentPage: 1,
  pageSize: 25,
  sortColumn: undefined,
  totalRecords: 0,
};

export const initialTableState: ITableState = {
  organizationTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  organizationEventsTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  supervisorTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  supervisorEventsTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  placementTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  placementEventsTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  userTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },

  userVolunteerTab: <TableSearchState> {
    textFilter: "",
    showInactive: false,
    results: <TableData[]> [],
    tableOptions: initialTableOptions
  },
};

const tableReducer = createReducer(
  initialTableState,

  on(setAdminOrganizationDashboardStateAction,
    (state, action): ITableState => ({
      ...state,
      organizationTab: action.newState
    })
  ),

  on(setAdminSupervisorDashboardStateAction,
    (state, action): ITableState => ({
      ...state,
      supervisorTab: action.newState
    })
  ),

  on(setAdminPlacementDashboardStateAction,
    (state, action): ITableState => ({
      ...state,
      placementTab: action.newState
    })
  ),

  on(setAdminUserDashboardStateAction,
    (state, action): ITableState => ({
      ...state,
      userTab: action.newState
    })
  ),

  on(setAdminUserVolunteerDashboardStateAction,
    (state, action): ITableState => ({
      ...state,
      userVolunteerTab: action.newState
    })
  ),

  on(setAdminOrganizationEventsStateAction,
    (state, action): ITableState => ({
      ...state,
      organizationEventsTab: action.newState
    })
  ),

  on(setAdminSupervisorEventsStateAction,
    (state, action): ITableState => ({
      ...state,
      supervisorEventsTab: action.newState
    })
  ),

  on(setAdminPlacementEventsStateAction,
    (state, action): ITableState => ({
      ...state,
      placementEventsTab: action.newState
    })
  ),
);

export function tableReducers(state: ITableState, action: Action) {
  return tableReducer(state, action);
}
