import { Resolve, Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { IOpportunity } from "../../../shared/model/opportunity.model";
import { OrganizationService } from "../../../core/service/organization.service";
import { IOrganization } from "../../../shared/model/organization.model";
import { IPastTermRegistrationResolverData } from "../past-term-registration-form.model";
import { IUserInfoComplete } from "../../../shared/model/userInfo.model";
import { AuthService } from "../../../core/service/auth.service";


@Injectable({ providedIn: 'root' })
export class PastTermRegistrationFormResolver implements Resolve<IPastTermRegistrationResolverData | undefined> {

  private userData?: IUserInfoComplete;
  private opportunity?: IOpportunity;
  private organizationData?: IOrganization;

  constructor(
    private router: Router,
    private authService: AuthService,
    private organizationService: OrganizationService) {}

  async resolve(): Promise<IPastTermRegistrationResolverData | undefined> {
    const routerState = this.router.getCurrentNavigation()?.extras.state;
    this.userData = await this.getUserData();
    this.opportunity = routerState ? routerState['opportunity'] : undefined;
    this.organizationData = await this.getOrganizationData();

    return this.opportunity ? this.serviceData : undefined;
  }

  private async getUserData(): Promise<IUserInfoComplete | undefined> {
    return await firstValueFrom(this.authService.getCurrentUserInfo());
  }

  private async getOrganizationData(): Promise<IOrganization | undefined> {
    if (!this.opportunity) return undefined;
    return await firstValueFrom(this.organizationService.getOrganization(this.opportunity.organizationId));
  }

  private get serviceData(): IPastTermRegistrationResolverData {
    // TODO: Add the data below to the store
    return {
      userData: this.userData,
      organizationData: this.organizationData,
    }
  }

}
