import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { IOpportunity } from '../../../../shared/model/opportunity.model';
import { updateOpportunitySuccessAction } from '../../opportunity/opportunity.actions';
import { setOpportunityDataAction } from '../opportunity-config.actions';
import { getOpportunitySelector } from '../opportunity-config.selectors';


@Injectable()
export class UpdateOpportunityEffect {
  constructor(private actions$: Actions, private store: Store) {}

  // noinspection TypeScriptValidateTypes
  updateOpportunity$ = createEffect(() => this.actions$.pipe(
    ofType(updateOpportunitySuccessAction),
    concatLatestFrom((action) => this.store.pipe(select(getOpportunitySelector))),
    filter(([action, currentData]) => !currentData || !this.shouldUpdateOpportunity(action.data, currentData)),
    map(([action]) => setOpportunityDataAction({ opportunity: this.getOpportunityData(action.data) }))
  ));

  private shouldUpdateOpportunity(newData: any, currentOpp: IOpportunity): boolean {
    return JSON.stringify(this.getOpportunityData(newData)) === JSON.stringify(currentOpp);
  }

  private getOpportunityData(actionData: any) {
    return ('opportunityChanges' in actionData) ? actionData.opportunityChanges : actionData;
  }
}
