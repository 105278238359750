import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminSettingsComponent} from './feature/admin-settings/admin-settings.component';
import {UploadsComponent} from './feature/uploads/uploads.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {StudentLandingPageComponent} from './landing-page/student-landing-page/student-landing-page.component';
import {studentGuardFn} from './routing-hub/guards/student.guard';
import {SupervisorLandingPageComponent} from "./landing-page/supervisor-landing-page/supervisor-landing-page.component";
import {AdminLandingPageComponent} from "./landing-page/admin-landing-page/admin-landing-page.component";
import {supervisorGuardFn} from "./routing-hub/guards/supervisor.guard";
import {adminGuardFn} from "./routing-hub/guards/admin.guard";
import {FormsModule} from "@angular/forms";
import {incomingImpersonateGuardFn} from "./routing-hub/guards/incoming-impersonate.guard";
import {accessRevokedGuardFn} from "./routing-hub/guards/access-revoked.guard";
import {AccessRevokedComponent} from "./feature/access-revoked/access-revoked.component";
import {FailedSubmissionsComponent} from "./feature/failed-submissions/failed-submissions.component";
import {
  PastTermRegistrationFormResolver
} from "./feature/past-term-registration-form/resolvers/past-term-registration-form.resolver";
import { PastTermRegistrationGuard } from "./feature/past-term-registration-form/guards/past-term-registration.guard";

const routes: Routes = [
  {
    path: '',
    data: { title: '' },
    component: LandingPageComponent,
    canActivate: [accessRevokedGuardFn],
    children: [
      {
        path: 'access-revoked',
        data: { title: 'Access Revoked' },
        component: AccessRevokedComponent,
        pathMatch: 'full',
      },
      {
        path: 'impersonate',
        data: { title: 'Impersonate' },
        canActivate: [incomingImpersonateGuardFn],
        pathMatch: 'full',
        children: []
      },
      {
        path: '',
        data: { title: '' },
        component: StudentLandingPageComponent,
        canActivate: [studentGuardFn],
        // canActivate: [incomingImpersonateGuardFn, studentGuardFn],
        children: [
          {
            path: '',
            data: { title: '' },
            redirectTo: 'student-dashboard',
            pathMatch: 'full'
          },
          {
            path: 'student-dashboard',
            data: { title: 'Student Dashboard' },
            canActivate: [studentGuardFn],
            loadChildren: () => import('./feature/student-dashboard/student-dashboard.module').then(m => m.StudentDashboardModule),
          },
          {
            path: 'opportunity',
            data: { title: 'Opportunity' },
            loadChildren: () => import('./feature/opportunity/opportunity.module').then(m => m.OpportunityModule),
          },
          {
            path: 'organization',
            data: { title: 'Organization' },
            loadChildren: () => import('./feature/organization/organization.module').then(m => m.OrganizationModule),
          },
          {
            path: 'search-opportunity',
            data: { title: 'Search Opportunity' },
            loadChildren: () => import('./feature/search-opportunity/search-opportunity.module').then(m => m.SearchOpportunityModule),
          },
          {
            path: 'register-opportunity/:opportunityId',
            data: { title: 'Register Opportunity' },
            loadChildren: () => import('./feature/register-opportunity/register-opportunity.module').then(m => m.RegisterOpportunityModule),
          },
          {
            path: 'evaluation',
            data: { title: 'Evaluation' },
            loadChildren: () => import('./feature/evaluation/evaluation.module').then(m => m.EvaluationModule)
          },
          {
            path: 'request-confirmation',
            data: { title: 'Request Confirmation' },
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'audit',
            data: { title: 'Audit' },
            loadChildren: () => import('./feature/audit/audit.module').then(m => m.AuditModule)
          },
          {
            path: 'past-term-registration',
            data: { title: 'Past Term Registration Form' },
            canActivate: [PastTermRegistrationGuard],
            resolve: { serviceRegistrationData: PastTermRegistrationFormResolver },
            loadChildren: () => import('./feature/past-term-registration-form/past-term-registration-form.module').then(m => m.PastTermRegistrationFormModule)
          }
        ]
      },
      {
        path: 'supervisor',
        data: { title: '' },
        component: SupervisorLandingPageComponent,
        canActivate: [supervisorGuardFn],
        children: [
          {
            path: 'my-opportunities/:opportunityId',
            data: { title: 'My Opportunities' },
            loadChildren: () => import('./feature/opportunity-config/opportunity-config.module').then(m => m.OpportunityConfigModule),
          },
          {
            path: 'edit-profile',
            data: { title: 'Edit Profile' },
            loadChildren: () => import('./feature/edit-profile/edit-profile-module').then(m => m.EditProfileModule),
          },
          {
            path: 'my-organizations/:organizationId',
            data: { title: 'My Organizations' },
            loadChildren: () => import('./feature/organization-config/organization-config.module').then(m => m.OrganizationConfigModule)
          },
          {
            path: 'organization',
            data: { title: 'Organization' },
            loadChildren: () => import('./feature/organization/organization.module').then(m => m.OrganizationModule),
          },
          {
            path: 'opportunity',
            data: { title: 'Opportunity' },
            loadChildren: () => import('./feature/opportunity/opportunity.module').then(m => m.OpportunityModule),
          },
          {
            path: 'opportunity-confirmation/:organizationId',
            data: { title: 'Opportunity Confirmation' },
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'opportunity-confirmation/edit/:opportunityId',
            data: { title: 'Opportunity Confirmation Edit' },
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'past-opportunities',
            data: { title: 'Past Opportunities' },
            loadChildren: () => import('./feature/past-opportunities/past-opportunities.module').then(m => m.PastOpportunitiesModule),
          },
          {
            path: 'service-evaluation',
            data: { title: 'Service Evaluation' },
            loadChildren: () => import('./feature/evaluation/evaluation.module').then(m => m.EvaluationModule)
          },
          {
            path: 'evaluation-confirmation',
            data: { title: 'Evaluation Confirmation' },
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'evaluation-correction',
            data: { title: 'Evaluation Correction' },
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'supervisor-no-organization',
            data: { title: 'Supervisor No Organization' },
            loadChildren: () => import('./feature/supervisor-no-org/supervisor-no-org.module').then(m => m.SupervisorNoOrgModule),
          },
          {
            path: 'supervisor-contract',
            data: { title: 'Supervisor Contract' },
            loadChildren: () => import('./feature/supervisor-contract/supervisor-contract.module').then(m => m.SupervisorContractModule),
          },
          {
            path: 'contract-submitted',
            data: { title: 'Contract Submitted' },
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'rollover/:organizationId',
            data: { title: 'Opportunity Rollover' },
            loadChildren: () => import('./feature/opportunity-rollover/opportunity-rollover.module').then(m => m.OpportunityRolloverModule)
          },
          {
            path: 'past-term-registration-requests',
            data: { title: 'Past Term Registrations' },
            canActivate: [PastTermRegistrationGuard],
            loadChildren: () => import('./feature/past-term-registration-requests/past-term-registration-requests.module').then(m => m.PastTermRegistrationRequestsModule),
          },
        ],
      },
      // {
      //   path: 'supervisor-contract', component: SupervisorContractComponent,
      // },
      {
        path: 'admin',
        data: { title: '' },
        component: AdminLandingPageComponent,
        canActivate: [adminGuardFn],
        children: [
          {
            path: '',
            data: { title: '' },
            redirectTo: 'users',
            pathMatch: 'full'
          },
          {
            path: 'organizations',
            data: { title: 'Organizations' },
            loadChildren: () => import('./feature/organization-admin/organization-admin.module').then(m => m.OrganizationAdminModule),
          },
          {
            path: 'supervisors',
            data: { title: 'Supervisors' },
            loadChildren: () => import('./feature/supervisor-admin/supervisor-admin.module').then(m => m.SupervisorAdminModule),
          },
          {
            path: 'placements',
            data: { title: 'Placements' },
            loadChildren: () => import('./feature/placement-admin/placement-admin.module').then(m => m.PlacementAdminModule),
          },
          {
            path: 'users',
            data: { title: 'Users' },
            loadChildren: () => import('./feature/user-admin/user-admin.module').then(m => m.UserAdminModule),
          },
          {
            path: 'edit-profile',
            data: { title: 'Edit Profile' },
            loadChildren: () => import('./feature/edit-profile/edit-profile-module').then(m => m.EditProfileModule),
          },
          {
            path: 'my-organizations/:id',
            data: { title: 'My Organizations' },
            loadChildren: () => import('./feature/organization-config/organization-config.module').then(m => m.OrganizationConfigModule)
          },
          {
            path: 'organization',
            data: { title: 'Organization' },
            loadChildren: () => import('./feature/organization/organization.module').then(m => m.OrganizationModule),
          },
          {
            path: 'opportunity',
            data: { title: 'Opportunity' },
            loadChildren: () => import('./feature/opportunity/opportunity.module').then(m => m.OpportunityModule),
          },
          {
            path: 'approvals',
            data: { title: 'Approvals' },
            loadChildren: () => import('./feature/approvals-admin/approvals.admin.module').then(m => m.ApprovalsAdminModule),
          },
          {
            path: 'announcements',
            data: { title: 'Announcements' },
            loadChildren: () => import('./feature/announcements-admin/announcements-admin.module').then(m => m.AnnouncementsAdminModule),
          },
          {
            path: 'terms',
            data: { title: 'Terms' },
            loadChildren: () => import('./feature/terms-admin/terms-admin.module').then(m => m.TermsAdminModule),
          },
          {
            path: 'failed-submissions',
            data: { title: 'Failed Submissions' },
            component: FailedSubmissionsComponent
          },
          {
            path: 'uploads',
            data: { title: 'Uploads' },
            component: UploadsComponent
          },
          {
            path: 'settings',
            data: { title: 'Global Settings' },
            component: AdminSettingsComponent
          }
        ],
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), FormsModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
