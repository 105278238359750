import { createReducer, on } from '@ngrx/store';
import * as PastTermRegistrationsActions from './past-term-registration.actions';
import { PastTermRegistrationsState, initialPastTermRegistrationsState } from './past-term-registrations.state';

export const pastTermRegistrationsReducer = createReducer(
  initialPastTermRegistrationsState,

  on(PastTermRegistrationsActions.loadPastTermRegistrations, (state) => ({
    ...state,
    loading: true,
    error: null
  })),

  on(PastTermRegistrationsActions.loadPastTermRegistrationsSuccess, (state, { registrations }) => ({
    ...state,
    registrations,
    loading: false,
    error: null
  })),

  on(PastTermRegistrationsActions.loadPastTermRegistrationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  }))
);
