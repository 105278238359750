import { CanActivate } from '@angular/router';
import { Observable } from "rxjs";
import { AvailableToggle } from "../../../shared/model/feature-toggle.model";
import { Injectable } from "@angular/core";
import { FeatureToggleService } from "../../../core/service/feature-toggle.service";


@Injectable({
  providedIn: 'root',
})
export class PastTermRegistrationGuard implements CanActivate {

  private readonly PAST_TERM_REGISTRATIONS: AvailableToggle = AvailableToggle.PAST_REGISTRATION;

  constructor(private featureToggleService: FeatureToggleService) {}

  canActivate(): Observable<boolean> {
    return this.isPastTermRegistrationEnabled();
  }

  private isPastTermRegistrationEnabled(): Observable<boolean> {
    return this.featureToggleService.isToggleActive(this.PAST_TERM_REGISTRATIONS);
  }
}
