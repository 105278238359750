import {Action, createReducer, on} from '@ngrx/store';
import { IOpportunity } from '../../../shared/model/opportunity.model';
import {stateHelperFunctions} from '../stateHelperFunctions';
import {
  approvedOpportunityChangeSuccessAction,
  getOpportunityAction,
  getOpportunityFailureAction,
  getOpportunitySuccessAction,
  searchOpportunitiesAction,
  searchOpportunitiesFailureAction,
  searchOpportunitiesSuccessAction,
  updateOpportunityAction,
  updateOpportunityFailureAction,
  updateOpportunitySuccessAction
} from './opportunity.actions';
import {IOpportunityState} from './opportunity.state';
import {getUserSupervisorOpportunitiesSuccessAction} from "../user-supervisor/user-supervisor.actions";


export const initialOpportunityState: IOpportunityState = {
  opportunities: [],
  lastSearchResults: [],
  loadingOpportunities: [],
  runningSearch: false,
  error: undefined
}

const opportunityReducer = createReducer(
  initialOpportunityState,

  on(getOpportunityAction, (state, action): IOpportunityState => {
      return stateHelperFunctions.hasItem(state, action.id) ? state : ({
        ...state,
        loadingOpportunities: state.loadingOpportunities.concat([action.id])
      })
    }
  ),

  on(updateOpportunityAction, (state, action): IOpportunityState => {
    return stateHelperFunctions.hasItem(state, action.data.id) ? state : ({
      ...state,
      loadingOpportunities: state.loadingOpportunities.concat([action.data.id])
    })
  }),

  on(getOpportunitySuccessAction, (state, action): IOpportunityState => ({
      ...state,
      loadingOpportunities: stateHelperFunctions.removeLoadingId(state.loadingOpportunities, action.data.id),
      opportunities: state.opportunities?.concat(action.data)
    })
  ),

  on(getOpportunityFailureAction,
    updateOpportunityFailureAction,
    (state, action): IOpportunityState => ({
      ...state,
      loadingOpportunities: stateHelperFunctions.removeLoadingId(state.loadingOpportunities, action.id),
      error: action.error.error
    })
  ),

  on(searchOpportunitiesAction, (state, action): IOpportunityState => ({
      ...state,
      runningSearch: true
    })
  ),

  on(searchOpportunitiesSuccessAction, (state, action): IOpportunityState => ({
      ...state,
      runningSearch: false,
      opportunities: state.opportunities?.concat(action.data.filter(result => !state.opportunities.some(opp => opp.id === result.id))),
      lastSearchResults: action.data
    })
  ),

  on(searchOpportunitiesFailureAction, (state, action): IOpportunityState => ({
      ...state,
      runningSearch: false,
      error: action.error.error
    })
  ),

  on(updateOpportunitySuccessAction, (state, action): IOpportunityState => ({
      ...state,
      loadingOpportunities: stateHelperFunctions.removeLoadingId(state.loadingOpportunities, action.data.id),
      opportunities: ('opportunityChanges' in action.data) ? updateNonChangeFields(state.opportunities, action.data) : stateHelperFunctions.replaceItemInArray(state.opportunities, action.data),
      error: undefined
    })
  ),

  on(getUserSupervisorOpportunitiesSuccessAction, (state, action): IOpportunityState => ({
    ...state,
    opportunities: state.opportunities?.concat(action.data.filter(result => !state.opportunities.some(opp => opp.id === result.id))),
    error: undefined,
  })),

  on(approvedOpportunityChangeSuccessAction, (state, action): IOpportunityState => ({
    ...state,
      opportunities: stateHelperFunctions.replaceItemInArray(state.opportunities, action.data),
      error: undefined
  }))
);

function updateNonChangeFields(opportunities: IOpportunity[], updatedOpportunity: any): Array<any> {
  let updatedOpp: IOpportunity = updatedOpportunity.opportunityChanges;
  const index = opportunities.findIndex(opp => opp.id == updatedOpportunity.opportunityId);
  let originalOpportunity = opportunities.find(opp => opp.id == updatedOpportunity.opportunityId);
  if(originalOpportunity) {
    let updatedOppWithoutChangeFields: IOpportunity = {
      ...updatedOpp,
      description: originalOpportunity.description,
      name: originalOpportunity.name,
      oneCreditOnly: originalOpportunity.oneCreditOnly,
      getLuPermissions: originalOpportunity.getLuPermissions,
    }

    let result: Array<any> = [];
    if (index >= 0) {
      return stateHelperFunctions.replaceItem(index, opportunities, updatedOppWithoutChangeFields, result);
    } else {
      return opportunities;
    }
  } else {
    return opportunities;
  }
}

export function opportunityReducers(state: IOpportunityState, action: Action) {
  return opportunityReducer(state, action);
}
