import { IServiceRegistration } from '../../../shared/model/service-registration.model';

export interface PastTermRegistrationsState {
  registrations: IServiceRegistration[];
  loading: boolean;
  error: string | null;
}

export const initialPastTermRegistrationsState: PastTermRegistrationsState = {
  registrations: [],
  loading: false,
  error: null,
};
