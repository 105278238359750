import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PastTermRegistrationsActions from './past-term-registration.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import {of, forkJoin, combineLatest} from 'rxjs';
import {OrganizationService} from "../../service/organization.service";
import {IServiceRegistration} from "../../../shared/model/service-registration.model";
import {SupervisorService} from "../../service/supervisor.service";

@Injectable()
export class PastTermRegistrationsEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private supervisorService: SupervisorService
  ) {}

  loadPastTermRegistrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PastTermRegistrationsActions.loadPastTermRegistrations),
      switchMap(({ supervisorId }) =>
        combineLatest([
          this.organizationService.getOrganizationsFromAll(supervisorId),
          this.supervisorService.getCurrentSupervisor()
        ]).pipe(
          switchMap(([organizations, currentSupervisor]) => {
            const orgRequests = organizations.map(org =>
              this.organizationService.getPastTermRegistrations(org.id).pipe(
                map((registrations: IServiceRegistration[]) =>
                  // If the logged-in supervisor is not the lead supervisor on the organization,
                  // filter out registrations where the sub-supervisor is not the supervisor on the registration.
                  currentSupervisor.id !== org.supervisorId
                    ? registrations.filter(reg => reg.supervisorId === currentSupervisor.id)
                    : registrations
                )
              )
            );
            return forkJoin(orgRequests);
          }),
          map((registrationsByOrg: IServiceRegistration[][]) =>
            PastTermRegistrationsActions.loadPastTermRegistrationsSuccess({
              registrations: registrationsByOrg.flat()
            })
          ),
          catchError((error) =>
            of(PastTermRegistrationsActions.loadPastTermRegistrationsFailure({ error: error.message }))
          )
        )
      )
    )
  );
}
