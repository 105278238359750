import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { AuthService } from '../../core/service/auth.service';
import { FeatureToggleService } from '../../core/service/feature-toggle.service';
import { OrganizationService } from '../../core/service/organization.service';
import { SupervisorService } from '../../core/service/supervisor.service';
import { UserService } from '../../core/service/user.service';
import { setHasOnlySubSupervisedOrganizations } from '../../core/store/user-supervisor/user-supervisor.actions';
import { AvailableToggle } from '../../shared/model/feature-toggle.model';
import { IOpportunity, OpportunityStatus } from '../../shared/model/opportunity.model';
import { IOrganization, OrganizationStatus } from '../../shared/model/organization.model';
import { ISupervisor } from '../../shared/model/supervisor.model';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
    standalone: false
})
export class SideBarComponent implements OnInit, OnDestroy {
  private endSubscriptions$ = new Subject();
  public userName!: string | undefined;
  public userEmail!: string | undefined;
  public nonLeadOrganizationTooltip: string = 'You are a member of this organization. If you do not see any service opportunity information, please contact the lead organization supervisor to be added to a volunteer opportunity. For further questions, please reach out to cser@liberty.edu';
  private supervisorId: string | undefined;

  //isAdmin shows as false unless the logged in user is an admin
  public isAdmin$!: Observable<boolean | undefined>;
  public isImpersonating$!: Observable<boolean | undefined>;

  public readonly PAST_TERM_REGISTRATIONS: AvailableToggle = AvailableToggle.PAST_REGISTRATION;
  public readonly OPPORTUNITY_REQUESTS: AvailableToggle = AvailableToggle.OPPORTUNITY_REQUESTS;

  @Output() public shouldCloseSideBar = new EventEmitter<boolean>();

  constructor(
    private store: Store,
    private authService: AuthService,
    private supervisorService: SupervisorService,
    private router: Router,
    private organizationService: OrganizationService,
    private userService: UserService,
    private featureToggleService: FeatureToggleService) {
  }

  public opportunities$!: Observable<IOpportunity[]>;
  public organizations: IOrganization[] = [];
  public nonLeadOrganizations: IOrganization[] = [];

  public isLoadingData$!: Observable<boolean>;
  public isContractSigned$!: Observable<boolean>;

  readonly INACTIVE: OrganizationStatus = OrganizationStatus.INACTIVE;

  ngOnInit() {
    //isAdmin is only true if the user logged into the app is an admin, shows as false when impersonating
    this.isAdmin$ = this.authService.isAdmin();
    this.isImpersonating$ = this.authService.isImpersonating();
    this.isContractSigned$ = this.supervisorService.getSupervisorContractSigned();

    this.supervisorService.getCurrentSupervisor().pipe(
      takeUntil(this.endSubscriptions$),
      switchMap((supervisor: ISupervisor) => {
        this.userName = `${supervisor.firstName} ${supervisor.lastName}`;
        this.userEmail = supervisor.contactEmail;
        this.supervisorId = supervisor.id;

        return this.organizationService.getOrganizationsFromLeadId(supervisor.id);
      })).subscribe((organizations: IOrganization[]) => {
      this.organizations = organizations.filter(organization => organization.status === OrganizationStatus.ACTIVE ||
        organization.status === OrganizationStatus.INACTIVE
        || organization.status === OrganizationStatus.PENDING_ADMIN_SIGNATURE
        || organization.status === OrganizationStatus.REJECTED).sort((a, b) => a.name.localeCompare(b.name));

      if(this.organizations.length === 0) {
        this.getNonLeadOrganizations()
      }
    });

    this.opportunities$ = this.supervisorService.getSupervisedOpportunitiesFromCurrentUser().pipe(
      combineLatestWith(this.isOpportunityRequestFeatureEnabled),
      map(([opportunities, isEnabled]) => this.filterOpportunities(opportunities, isEnabled))
    );
  }

  private filterOpportunities(opportunities: IOpportunity[], isOpportunityRequestsEnabled: boolean): IOpportunity[] {
    return opportunities.filter(opportunity =>
      opportunity.status === OpportunityStatus.ACTIVE ||
      (isOpportunityRequestsEnabled && (
        opportunity.status === OpportunityStatus.PENDING_ADMIN_SIGNATURE ||
        opportunity.status === OpportunityStatus.REJECTED
      ))
    ).sort((a,b) => a.name.localeCompare(b.name));
  }

  private getNonLeadOrganizations() {
    if(this.supervisorId) {
      this.organizationService.getOrganizationsFromSubId(this.supervisorId).subscribe(results => {
        this.nonLeadOrganizations = results;

        if(this.nonLeadOrganizations.length > 0) {
          this.store.dispatch(setHasOnlySubSupervisedOrganizations({data: true}));
        }
      })
    }
  }

  public orgStatusDisplay(status: OrganizationStatus): string {
    switch (status) {
      case OrganizationStatus.INACTIVE:
        return 'Inactive';
      case OrganizationStatus.PENDING_ADMIN_SIGNATURE:
        return 'Pending';
      case OrganizationStatus.REJECTED:
        return 'Rejected';
      default:
        return '';
    }
  }

  public oppStatusDisplay(status: OpportunityStatus): string {
    switch (status) {
      case OpportunityStatus.PENDING_ADMIN_SIGNATURE:
        return 'Pending';
      case OpportunityStatus.REJECTED:
        return 'Rejected';
      default:
        return '';
    }
  }

  public navigateToOrgWithKeyboard(org: IOrganization) {
    this.router.navigate(this.getOrgRoute(org));
  }

  public navigateToOppWithKeyboard(oppId: string) {
    this.router.navigate(['/', 'supervisor', 'my-opportunities', oppId]);
  }

  public getOrgRoute(org: IOrganization) : string[] {
    if (org.status === OrganizationStatus.INACTIVE || org.status === OrganizationStatus.REJECTED) {
      return ['/', 'supervisor', 'organization', 'details', org.id];
    } else {
      return ['/', 'supervisor', 'my-organizations', org.id];
    }
  }

  public getOppRoute(opp: IOpportunity): string[] {
    if (opp.status === OpportunityStatus.REJECTED || opp.status === OpportunityStatus.PENDING_ADMIN_SIGNATURE) {
      return ['/', 'supervisor', 'opportunity', 'details', opp.id];
    } else {
      return ['/', 'supervisor', 'my-opportunities', opp.id];
    }
  }

  public logOut(): void {
    this.userService.signOut();
  }

  ngOnDestroy() {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.complete();
  }

  protected readonly OrganizationStatus = OrganizationStatus;

  public closeSideBar(): void {
    this.shouldCloseSideBar.emit(true);
  }

  public get isPastTermRegistrationFeatureEnabled(): Observable<boolean> {
    return this.featureToggleService.isToggleActive(this.PAST_TERM_REGISTRATIONS);
  }

  public get isOpportunityRequestFeatureEnabled(): Observable<boolean>{
    return this.featureToggleService.isToggleActive(this.OPPORTUNITY_REQUESTS);
  }

  protected readonly OpportunityStatus = OpportunityStatus;
}
