<div [style.height]="(isImpersonating$ | async) ? 'calc(100vh - 48px)' : '100vh'" class="f-row main">
  @if (!(modalIsOpen$ | async)) {
    <button class="open-side-bar" (click)="openSideBar()">
      <app-icon icon="menu" class="menu-icon"></app-icon>
    </button>
  }
  <div class="side-bar-wrapper h-100" [ngClass]="sideBarState">
    <app-admin-side-bar class="side-bar" (shouldCloseSideBar)="closeSideBar($event)"></app-admin-side-bar>
  </div>
  <div class="router">
    <router-outlet></router-outlet>
  </div>
</div>
