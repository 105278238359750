import {IUserSupervisorState} from "./user-supervisor.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  addNewOpportunityToSupervisedOpportunitiesAction,
  getHasSignedFailureAction,
  getHasSignedSuccessAction,
  getUserSupervisorOpportunitiesFailureAction,
  getUserSupervisorOpportunitiesSuccessAction,
  saveAgreementFailureAction,
  saveAgreementSuccessAction, setHasOnlySubSupervisedOrganizations
} from './user-supervisor.actions';
import {updateOpportunitySuccessAction} from "../opportunity/opportunity.actions";
import {stateHelperFunctions} from "../stateHelperFunctions";
import {opportunitySort} from "../../../shared/function/opportunity-sort";

export const initialUserSupervisorState: IUserSupervisorState = {
  opportunities: [],
  error: undefined,
  hasSigned: false,
  hasOnlySubSupervisedOrganizations: false
}

const userSupervisorReducer = createReducer(
  initialUserSupervisorState,

  on(getUserSupervisorOpportunitiesSuccessAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      opportunities: action.data,
      error: undefined
    })
  ),

  on(getUserSupervisorOpportunitiesFailureAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      opportunities: [],
      error: action.error.error
    })
  ),

  on(updateOpportunitySuccessAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      opportunities: stateHelperFunctions.replaceItemInArray(state.opportunities, action.data),
      error: undefined
    })
  ),

  on(addNewOpportunityToSupervisedOpportunitiesAction,
    (state, action): IUserSupervisorState => {
      const updatedOpportunities = state.opportunities.concat(action.data)
        .sort(opportunitySort);

      return {
        ...state,
        opportunities: updatedOpportunities,
        error: undefined
      };
    }
  ),

  on(getHasSignedSuccessAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      hasSigned: action.status,
    })
  ),

  on(getHasSignedFailureAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      hasSigned: false,
      error: action.error.error
    })
  ),

  on(saveAgreementSuccessAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      hasSigned: true
    })
  ),

  on(saveAgreementFailureAction,
    (state, action): IUserSupervisorState => ({
      ...state,
      hasSigned: false,
      error: action.error.error
    })
  ),

  on(setHasOnlySubSupervisedOrganizations,
    (state, action): IUserSupervisorState => ({
      ...state,
      hasOnlySubSupervisedOrganizations: action.data
    }))
);

export function userSupervisorReducers(state: IUserSupervisorState, action: Action) {
  return userSupervisorReducer(state, action);
}
